import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/sections/Global/Footer";
import PressBox from "../components/sections/Global/PressBox";
import cryptonews from "../assets/images/cryptonews.png";
import blockster from "../assets/images/blockster.png";
import decrypt from "../assets/images/decrypt.png";
import zeebu from "../assets/images/zeebu.jpeg";
import trumpsTenseResponse from "../assets/images/trumps-tense-response.png";
import shibetf from "../assets/images/shibetf.png";
import maskedMarvel from "../assets/images/masked_marvel.png";
import zeebuxsilentswap from "../assets/images/ZeebuxSilentSwap.png";
import silentswaplaunch from "../assets/images/silentswapLaunch.png";
import ibitetf from "../assets/images/ibitetf.png";
import btcetf from "../assets/images/btcetf.png";
import silentswapprivacy from "../assets/images/silentswapprivacy.png";
import silentswapchain from "../assets/images/SilentSwapchain.png";
import squidgrow from "../assets/images/squidgrow.png";
import Dogecoin from "../assets/images/Dogecoin.png";
import msnmoney from "../assets/images/msnmoney.png";
import SSpic from "../assets/images/SSpic.png";
import Tornadocrypto from "../assets/images/Tornadocrypto.png";
import magazineShibtoshi from "../assets/images/magazineShibtoshi.png";
import shibcoindesk from "../assets/images/shibcoindesk.png";
function Press() {
  return (
    <>
      <header className="header">
        <Navbar />
      </header>
      <main>
        <h1 className="maintitle">Press</h1>
        <section className="press-section">
          <PressBox
            title="BUSINESS INSIDER"
            image={zeebu}
            headline="SilentSwap Partners with Zeebu to Revolutionize Privacy in Telecom Settlements"
            description="SilentSwap and Zeebu's collaboration is poised to revolutionize privacy standards in the telecom industry."
            link="https://markets.businessinsider.com/news/currencies/silentswap-partners-with-zeebu-to-revolutionize-privacy-in-telecom-settlements-1033647075"
          />
          <PressBox
            title="MEDIUM"
            image={cryptonews}
            headline="Explore More on SquidGrow's Medium"
            description="Stay updated with the latest news and insights from SquidGrow on our Medium page."
            link="https://medium.com/@SquidGrow"
          />
          <PressBox
            title="ETH NEWS"
            image={shibetf}
            headline="Shibtoshi's SquidGrow: The Ultimate Utility Meme Token on BSC and Ethereum"
            description="ETH News covers SquidGrow's unique position as a utility-focused meme token operating across multiple chains."
            link="https://www.ethnews.com/shibtoshis-squidgrow-the-ultimate-utility-meme-token-on-bsc-and-ethereum/"
          />
          <PressBox
            title="CBS"
            image={trumpsTenseResponse}
            headline="Trump's cryptocurrency campaign stop in Nashville"
            description="Former President Donald Trump made a campaign stop in Nashville, Tennessee, where he discussed cryptocurrency and digital assets."
            link="https://www.cbsnews.com/news/trump-cryptocurrency-campaign-nashville/"
          />
          <PressBox
            title="Bitcoinist"
            image={maskedMarvel}
            headline="SquidGrow's Masked Marvel Shibtoshi Captivates the Bitcoin 2024 Conference"
            description="Bitcoinist covers Shibtoshi's appearance at the Bitcoin 2024 Conference, highlighting SquidGrow's growing influence in the crypto space."
            link="https://bitcoinist.com/squidgrows-masked-marvel-shibtoshi-captivates-the-bitcoin-2024-conference/"
          />
          <PressBox
            title="GLOBE NEWSWIRE"
            image={zeebuxsilentswap}
            headline="Zeebu Announces Strategic Partnership with SilentSwap"
            description="Zeebu partners with SilentSwap, a privacy-focused DEX aggregator powered by Secret Network, to enhance privacy in telecom settlements."
            link="https://www.globenewswire.com/news-release/2024/08/06/2925362/0/en/Zeebu-Announces-Strategic-Partnership-with-SilentSwap.html"
          />
         
          <PressBox
            title="The CryptoPolitan"
            image={silentswaplaunch}
            headline="SilentSwap Launch Set to Redefine DeFi with Unmatched Privacy and Security"
            description="SilentSwap's upcoming launch promises to revolutionize DeFi by offering unprecedented privacy and security features for crypto transactions."
            link="https://www.cryptopolitan.com/silentswap-launch-set-to-redefine-defi-with-unmatched-privacy-and-security/"
          />
          
          <PressBox
            title="Forbes"
            image={ibitetf}
            headline="BlackRock ETF Demonstrates How Institutional Products Improve Crypto's Stability"
            description="Forbes examines how BlackRock's ETF and other institutional investment products are contributing to increased stability in cryptocurrency markets."
            link="https://www.forbes.com/sites/lawrencewintermeyer/2024/08/22/blackrock-etf-demonstrates-how-institutional-products-improve-cryptos-stability/"
          />
          <PressBox
            title="Forbes"
            image={btcetf}
            headline="Crypto Edges Towards Mass Adoption with SEC Approval of Options for BTC ETFs"
            description="Forbes examines how the SEC's approval of Bitcoin ETF options marks a significant milestone in cryptocurrency's journey toward mainstream adoption."
            link="https://www.forbes.com/sites/lawrencewintermeyer/2024/10/10/crypto-edges-towards-mass-adoption-with-sec-approval-of-options-for-btc-etfs/"
          />
          <PressBox
            title="Crypto News"
            image={silentswapprivacy}
            headline="SilentSwap: Revolutionizing Cross-Chain Swaps with Privacy-First Transactions"
            description="An in-depth look at how SilentSwap is transforming the DeFi landscape with its innovative approach to private cross-chain transactions."
            link="https://cryptonews.net/editorial/technology/silentswap-revolutionizing-cross-chain-swaps-with-privacy-first-transactions/"
          />
          <PressBox
            title="GritDaily"
            image={silentswapchain}
            headline="SilentSwap: Revolutionizing Cross-Chain Swaps with Privacy-First Transactions"
            description="An in-depth look at how SilentSwap is transforming the DeFi landscape with its innovative approach to private cross-chain transactions."
            link="https://gritdaily.com/silentswap-revolutionizing-cross-chain-swaps/"
          />
          <PressBox
            title="Blockster"
            image={squidgrow}
            headline="Long-Term Utility Helps SquidGrow Stand Out in the High-Risk, Quick-Profit World of Meme Coins"
            description="An analysis of how SquidGrow's focus on sustainable utility and long-term value creation differentiates it from typical meme coins in the cryptocurrency market."
            link="https://blockster.com/long-term-utility-helps-squidgrow-stand-out-in-the-high-risk-quick-profit-world-of-meme-coins"
          />
          <PressBox
            title="Blocktelegraph"
            image={Dogecoin}
            headline="All Eyes on SquidGrow as Next Dogecoin Successor"
            description="An analysis of SquidGrow's potential to follow in Dogecoin's footsteps as the next major meme coin success story in the cryptocurrency market."
            link="https://blocktelegraph.io/all-eyes-on-squidgrow-as-next-dogecoin-successor/"
          />
          <PressBox
            title="MSN Money"
            image={msnmoney}
            headline="Crypto Policy Is Heating Up Ahead of Close US Elections"
            description="An analysis of how cryptocurrency policy and regulation are becoming increasingly important topics as the United States approaches critical elections."
            link="https://www.msn.com/en-us/money/markets/crypto-policy-is-heating-up-ahead-of-close-us-elections/ar-AA1sXMBb?ocid=finance-verthp-feeds"
          />
          <PressBox
            title="U.Today"
            image={SSpic}
            headline="SilentSwap Introduces Privacy-Focused DEX Aggregator on Secret Network"
            description="SilentSwap launches a groundbreaking DEX aggregator on Secret Network, bringing enhanced privacy features to decentralized trading."
            link="https://u.today/press-releases/silentswap-introduces-privacy-focused-dex-aggregator-on-secret-network"
          />
             <PressBox
            title="Cryptodaily"
            image={Tornadocrypto}
            headline="Tornado Cash Ruling Sparks Crypto Privacy Triumph: SilentSwap Leads the Way for Effortless Private Trading"
            description="Analysis of how the Tornado Cash ruling impacts crypto privacy and SilentSwap's role in advancing private DeFi trading solutions."
            link="https://cryptodaily.co.uk/2024/12/tornado-cash-ruling-sparks-crypto-privacy-triumph-silentswap-leads-the-way-for-effortless-private-trading"
          />
             <PressBox
            title="Blockleaders"
            image={SSpic}
            headline="SilentSwap Launches to Redefine Privacy and Security in Decentralized Finance"
            description="SilentSwap launches a groundbreaking DEX aggregator on Secret Network, bringing enhanced privacy features to decentralized trading."
            link="https://www.blockleaders.io/news/silentswap-launches-to-redefine-privacy-and-security-in-decentralized-finance-"
          />
             <PressBox
            title="Techbullion"
            image={SSpic}
            headline="SilentSwap Integrates Squid to Unlock Next-Level Cross-Chain Access to Crypto Ecosystem"
            description="SilentSwap partners with Squid to enhance cross-chain capabilities, enabling seamless and private access across multiple blockchain networks."
            link="https://techbullion.com/silentswap-integrates-squid-to-unlock-next-level-cross-chain-access-to-crypto-ecosystem/"
          />
             <PressBox
            title="Cointelegraph"
            image={magazineShibtoshi}
            headline="How 'Shibtoshi' Gambled $95K to Become a Shiba Inu Billionaire"
            description="A fascinating story of how an early Shiba Inu investor turned a $95,000 investment into billions through strategic crypto trading and diamond hands."
            link="https://cointelegraph.com/magazine/how-shibtoshi-gambled-95k-shiba-inu-billionaire/"
          />
             <PressBox
            title="CoindeskTV"
            image={shibcoindesk}
            headline="'Shibtoshi' on the Crypto Gamble"
            description="Watch how an early Shiba Inu investor turned a $95,000 investment into billions through strategic crypto trading and diamond hands."
            link="https://www.coindesk.com/video/shibtoshi-on-the-crypto-gamble"
          />
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Press;
